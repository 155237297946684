import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import { paramsFilter } from '@/libs/utils'
// import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import dataV from '@jiaminghi/data-view' // 使用datav
import { localize, extend, validate } from "vee-validate";
import zh from "vee-validate/dist/locale/zh_CN.json";
Vue.use(validate);
import { required, email, integer, min_value } from "vee-validate/dist/rules"; // 按需引入已有的规则
localize("zh", zh);
extend("required", {
  ...required,
  message: "{_field_}不可为空",
});
import './global-components'

import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
// 请求参数过滤
Vue.prototype.$queryFilter = paramsFilter;
Vue.use(VueCompositionAPI)
Vue.use(dataV)
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

require('@core/scss/core.scss')

require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  // i18n,
  render: h => h(App),
}).$mount('#app')
