import { trasformerMarker } from "@/store/mixins/FMap_mixin"
import router from '@/router/index';

const state = {
  locList: [],
  alarmParams: { "fence_id{}": "=null" },
  markersMap: new Map(),
  mapActive: 0, // 地图当前标签
  mapCenter: null, // 地图中心点
  mapZoom: null, // 地图缩放等级
  nearbyCameras: [], // 需要被渲染的附近摄像头
}

const mutations = {
  SET_LOC(state, loc) {
    state.locList.push(loc);
  },
  REMOVE_LOC(state) {
    state.locList.splice(0, 1);
  },
  SET_ALARM_PARAMS(state, val) {
    state.alarmParams = val
  },
  SET_LAYER_MARKERS(state, val) {
    const { sn, markers } = val;
    state.markersMap.set(sn, markers)
  },
  SET_MAP_ACTIVE(state, val) {
    state.mapActive = val
  },
  SET_CENTER(state, { x, y }) {
    const setZoomCenter = () => {
      let center = null;
      if (Number.isInteger(x) || Number.isInteger(y)) {
        center = trasformerMarker({ x, y });
      }
      state.mapCenter = center;
      state.mapZoom = 25;
      setTimeout(() => {
        state.mapCenter = null;
        state.mapZoom = null;
      }, 2000);
    }
    if (router.currentRoute.name != 'dashboard-map') {
      router.push('/dashboard/map')
      setTimeout(() => {
        setZoomCenter();
      }, 3000);
      return
    }
    setZoomCenter();
  },
  
  SET_NEARBY_CAMERA(state, val) {
    state.nearbyCameras = val;
  },
}

const actions = {
  testLoc({ commit, state }) {
    setInterval(() => {
      commit('SET_LOC', { x: 100, y: 300, sn: 1 })
    }, 1000);
  }
}

export default {
  state,
  mutations,
  actions
}
