import Vue from 'vue'
import store from '@/store';
import router from '@/router/index';
// axios
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const errorCode = {
  412: '账号未启用，请联系管理员！',
  // 401: '登录已过期，请重新登录！',
  407: '未登录，请重新登录！',
}
const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
})

axiosIns.interceptors.response.use(
  response => {
    const res = response.data;

    if (res.code in errorCode) {
      Vue.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: '登录信息实效，请重新登录',
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      });

      // store.dispatch("user/logout");
      router.push(`/login`);
      // router.push(`/login?redirect=${router.currentRoute.fullPath}`);
      // removeLogin();
    }
    if (res.code != 200) {
      return Promise.reject(new Error(res.message || 'Error'));
    } else {
      return response;
    }
  },
  error => {
    console.log('err' + error);
    Vue.$toast({
      component: ToastificationContent,
      position: "top-right",
      props: {
        title: error.message,
        icon: 'XCircleIcon',
        variant: 'danger',
      },
    });
    return Promise.reject(error);
  }
)


Vue.prototype.$http = axiosIns

export default axiosIns
