import axiosIns from '../axios'

// 查询数据列表
export function getTerminalList(params = {}, page = {}) {
  return axiosIns.post('/get', {
    '[]': {
      Device: {
        ...params,
        "@order": "id-",
      },
      DeviceStatus: {
        "device_id@": "[]/Device/id"
      },
      query: 2,
    },
    '@role': 'OWNER',
    "total@": "/Device[]/total",
  })
}

// 查询所有设备类型
export function getDeviceTypes() {
  return axiosIns.post('/get', {
    'DeviceType[]': {
      DeviceType: {
        "@order": "id-",
      },
    },
    "total@": "/DeviceType[]/total",
  })
}

// 查询终端&绑定人信息
export function getDevideWithBinder(params = {}) {
  return axiosIns.post('/get', {
    '[]': {
      Device: {
        ...params,
        "@order": "id-",
      },
      Binder: {
        "id@": "[]/Device/binder_id"
      },
      DeviceStatus: {
        "device_id@": "[]/Device/id"
      },
      count: 200,
      query: 2,
    },
    '@role': 'OWNER',
    "total@": "/Device[]/total",
  })
}



// 保存设备
export function saveDevice(action, params = {}) {
  return axiosIns.post(`${action == 'add' ? '/post' : '/put'}`, {
    'Device': {
      ...params,
    },
    "tag": "Device",
    '@role': 'OWNER',
  })
}

// 添加设备
export function addTerminal(params = {}) {
  return axiosIns.post('/post', {
    'Device': {
      ...params,
    },
    "tag": "Device",
    '@role': 'OWNER',
  })
}

// 编辑设备信息
export function editTerminal(params = {}) {
  return axiosIns.post('/put', {
    'Device': {
      ...params,
    },
    "tag": "Device",
    '@role': 'OWNER',
  })
}

// 批量编辑设备信息
export function editTerminals(params = []) {
  return axiosIns.post('/put', {
    'Device[]': params,
    "tag": "Device:[]",
    '@role': 'OWNER',
  })
}

// 删除设备信息
export function delTerminal(id) {
  return axiosIns.post('/delete', {
    'Device': {
      "id{}": [id],
    },
    "tag": "Device",
    '@role': 'OWNER',
  })
}