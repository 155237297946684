import Vue from 'vue'
import VueRouter from 'vue-router'
import { getKeyData } from '@/libs/utils/auth'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// Routes
// import { canNavigate } from '@/libs/acl/routeProtection'
import dashboard from './routes/dashboard'
import pages from './routes/pages'

Vue.use(VueRouter)

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard-map' } },
    ...dashboard,
    ...pages,
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = getKeyData('login');
  const hasNav = getKeyData('nav');
  if (to.name == 'auth-login') return next();
  if (isLoggedIn) {
    if (!hasNav) {
      next({ name: 'auth-login' });
      Vue.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: `登录失败`,
          icon: "AlertCircleIcon",
          variant: "warning",
          text: `登录信息失效，请重新登录`,
        },
      });
    }
    return next()
  } else {
    next({ name: 'auth-login' })
  }
  next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
