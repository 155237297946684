import axiosIns from '../axios'

console.log(axiosIns);

export function login(data) {
  return axiosIns({
    url: '/sys/login',
    method: 'post',
    data
  })
}

export function getUserById(params) {
  return axiosIns.post('/get', {
    '[]': {
      User: {
        ...params,
        "@order": "id-",
      },
      Privacy: {
        "id@": "[]/User/id",
      },
    },
    query: 2,
    "tag": "User[]",
    '@role': 'OWNER',
  })
}

// 查询用户信息
export function getUsersList(params, page) {
  return axiosIns.post('/get', {
    '[]': {
      User: {
        ...params,
        "@order": "id-",
      },
      Privacy: {
        "id@": "[]/User/id",
      },
      ...page,
      query: 2,
    },
    '@role': 'ADMIN',
    "total@": "/[]/total",
  })
}

// 添加用户隐私
export function addUserInfo(params) {
  return axiosIns.post('/post', {
    Privacy: { ...params },
    "tag": "Privacy",
    '@role': 'ADMIN',
  })
}

// 获取用户隐私
export function getUserInfo(user_id) {
  return axiosIns.post('/get', {
    Privacy: {
      user_id,
    },
    "tag": "Privacy",
    '@role': 'ADMIN',
    // '@role': 'OWNER',
  })
}

// 删除用户信息
export function delUser(id) {
  return axiosIns.post('/delete', {
    'User': {
      "id{}": [id],
    },
    "tag": "User",
    '@role': 'OWNER',
  })
}

// 保存用户
export function saveUser(action, params = {}) {
  return axiosIns.post(`${action == 'add' ? '/post' : '/put'}`, {
    'User': {
      ...params,
    },
    "tag": "User",
    '@role': 'OWNER',
  })
}

