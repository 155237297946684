import axiosIns from '../axios'
// 查询数据列表
export function getRoleList(params = {}) {
  return axiosIns.post('/get', {
    'Role[]': {
      Role: {
        ...params,
        "@order": "id+",
      },
      count: 100,
      query: 2,
    },
    '@role': 'OWNER',
    "total@": "/Role[]/total",
  })
}

// 查询权限类型
export function getRoleType() {
  return axiosIns.post('/get', {
    'RoleType[]': {
      RoleType: {
        "@order": "id-",
      },
      query: 2,
    },
    "total@": "/RoleType[]/total",
  })
}


// 根据角色id获取权限
export function getRoleById(role_id) {
  return axiosIns.post('/get', {
    "[]": {
      RoleRes: {
        role_id,
      },
      "Resource[]": {
        Resource: {
          "id@": "[]/RoleRes/res_id",
        },
      },
      count: 100,
      "@order": "id-",
    },
    query: 2,
    "tag": "RoleRes[]",
  })
}

// 添加权限
export function addRole(role = {}, roleRes = []) {
  return axiosIns.post('/post', {
    Role: role,
    "RoleRes[]": roleRes,
    "tag": "Role:{}",
    "@role": "OWNER"
  })
}

// 添加权限
export function addRoleByRoleId(params) {
  return axiosIns.post('/post', {
    "RoleRes[]": params,
    "tag": "RoleRes:{}",
    "@role": "OWNER"
  })
}

// 编辑权限
export function editRole(params) {
  return axiosIns.post('/put', {
    Role: { ...params },
    "tag": "Role",
    "@role": "OWNER"
  })
}

// 批量编辑权限信息
export function editRoles(params = []) {
  return axiosIns.post('/put', {
    'Role[]': params,
    "tag": "Role:[]",
    '@role': 'OWNER',
  })
}

// 删除权限信息
export function delRole(id) {
  return axiosIns.post('/delete', {
    'Role': {
      "id{}": [id],
    },
    "tag": "Role",
    '@role': 'ADMIN',
  })
}

// 批量删除权限
export function delRoleResByIds(ids) {
  return axiosIns.post('/delete', {
    'RoleRes': {
      'id{}': ids
    },
    "tag": "RoleRes",
    '@role': 'OWNER',
  })
}