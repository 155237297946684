import { login } from '@/libs/api/user'
import { getRoleById, getRoleList } from '@/libs/api/role'
import { getKeyData, setKeyData } from '@/libs/utils/auth'
import { $themeNavIcons } from '@themeConfig'

const navHeader = {
  resources: '资源管理',
  permission: '系统/权限管理'
}

const state = {
  userInfo: getKeyData('login'),
  userNav: getKeyData('nav'),
  rolesList: null,
}

const mutations = {
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  SET_USER_NAV: (state, userNav) => {
    state.userNav = userNav;
  },
  SET_ROLES_LIST(state, rolesList) {
    state.rolesList = rolesList;
  }
}

const actions = {
  // 获取角色列表
  getRolesList({ commit, state }, refresh) {
    return new Promise((resolve, reject) => {
      // 有数据直接返回
      if (state.rolesList && !refresh) return resolve(state.rolesList);
      getRoleList({}, { count: 100 }).then(res => {
        console.log('没有角色列表数据，执行请求-----------', res)
        if (res.data && res.data.code == 200 && res.data['Role[]']) {
          commit('SET_ROLES_LIST', res.data['Role[]'])
        }
        resolve(res.data['Role[]'])
      })
    })
  },
  // 获取用户信息
  getUserRouter({ commit }, role_id) {
    return new Promise((resolve, reject) => {
      getRoleById(role_id).then(res => {
        if (res.data && res.data.code == 200 && "[]" in res.data) {
          let header = null;
          const resource = res.data["[]"].reduce((crr, next, index) => {
            let { router: route, name: title } = next["Resource[]"][0]
            route = route.replace(/\//g, "-").substring(1);
            // 设置头部标题
            const routeH = route.split('-')[0].toLowerCase();
            if (navHeader[routeH] && routeH !== header) {
              header = routeH;
              crr.push({ header: navHeader[routeH] });
            }
            crr.push({ route, title, icon: $themeNavIcons[route] });
            return crr;
          }, []);
          setKeyData('nav', resource)
          commit('SET_USER_NAV', resource)
        }
        resolve();
      })
    })
  },

  // 用户登录
  async login({ commit, dispatch }, userInfo) {
    console.log(userInfo);
    const { username, password } = userInfo;
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password }).then(async response => {
        const userInfo = response.data.user
        console.log('获取用户信息', userInfo)
        // 用户登录设置userInfo
        commit('SET_USER_INFO', userInfo)
        setKeyData('login', userInfo)
        await dispatch('getUserRouter', userInfo.role_id)
        resolve()
      }).catch(error => {
        console.log(error)
        reject(error)
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
