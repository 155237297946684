import CoordTransformer from '@/libs/resource/coordtransformer.js'

//原始坐标系参数 三个角点
var origonParas = [];

origonParas[0] = { 'x': 0, 'y': 0 }; //坐标角点 原点 
origonParas[1] = { 'x': 1000, 'y': 0 }; //X轴终点原始坐标
origonParas[2] = { 'x': 0, 'y': 1500 }; //Y轴终点原始坐标

//目标坐标系参数 三个角点
var targetParas = [];

targetParas[0] = { "x": 12596378.31489483, "y": 2638300.4077585693 }; //地图最小坐标角点
targetParas[1] = { 'x': 12596378.312023157, 'y': 2638306.4304182823 }; //X轴终点地图坐标
targetParas[2] = { 'x': 12596392.749739368, 'y': 2638300.4228040827 }; //Y轴终点地图坐标

function trasformer(model, { x, y }) {
  let coordTransformer = new CoordTransformer();
  model == 'xy' && coordTransformer.init(targetParas, origonParas); // 地图坐标系转x, y
  model == 'map' && coordTransformer.init(origonParas, targetParas); // x,y 转地图坐标系
  return coordTransformer.transform({ x, y })
}

//转换器初始化
export default trasformer