const keys = {
  login: 'userData', // 登录
  nav: 'userNav' // 用户菜单
}

export function getKeyData(key) {
  const data = localStorage.getItem(keys[key]);
  return data && JSON.parse(data);
}

export function setKeyData(key, keyData) {
  return localStorage.setItem(keys[key], JSON.stringify(keyData))
}

export function removeKeyData(key) {
  return localStorage.removeItem(keys[key])
}

