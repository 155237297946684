
import md5 from 'js-md5'

function TestSockets(callback) {
  const { name: src, password: pwd } = JSON.parse(localStorage.getItem('userData'));
  var webSocket = new WebSocket(process.env.VUE_APP_BASE_SOCKET);
  var message = `{"cmd":"LOGIN","device":"device","os":"os", "src": "${src}", "pwd":"${md5('123456')}","v":"version"}`;
  webSocket.onopen = () => {
    webSocket.send(message);
  }

  webSocket.onmessage = (event) => {
    console.log('收到webSocket数据', event)
    callback(event);
  }
}

export { TestSockets }
