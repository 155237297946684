import { getNowTime } from "@/libs/utils/index"
const state = {
  alarm: [],
}

const mutations = {
  SET_ALARM(state, val) {
    console.log(val)
    const index = state.alarm.findIndex(res => {
      return res.sn == val.sn;
    })
    const notice = {
      ...val,
      title: `${val.sn}发出报警`,
      subtitle: getNowTime(),
      icon: "AlertTriangleIcon",
      type: "light-danger",
    }
    if (index != -1) {
      state.alarm.splice(index, 1, notice)
    } else {
      state.alarm.push(notice);
    }
    console.log('报警列队', state.alarm)
  },
  CLEAR_ALARM(state, sn) {
    if (!sn) state.alarm = [];
    const index = state.alarm.findIndex(res => res.sn == sn)
    state.alarm.splice(index, 1)
  },
}

const actions = {

}

export default {
  state,
  mutations,
  actions
}
