export const $themeColors = {}

export const $themeBreakpoints = {}

export const $themeNavIcons = {
  'mapView': 'MonitorIcon',
  'dashboard-map': 'MapIcon',
  'visitor-visitor': 'UsersIcon',
  'Track-track': 'GitBranchIcon',
  'Fence-fence': 'GridIcon',
  'Alarm-alarm': 'RadioIcon',
  'Resources-area': 'PieChartIcon',
  'Resources-terminal': 'ServerIcon',
  'Resources-personnel': 'UserIcon',
  'Permission-resource': 'ListIcon',
  'Permission-role': 'KeyIcon',
  'Permission-account': 'TrelloIcon',
  'Permission-log': 'ClipboardIcon'
}

export const $themeConfig = {
  app: {
    appName: '室内定位系统',
    appLogoImage: require('@/assets/images/logo/logo.svg'), 
  },
  layout: {
    isRTL: false,
    skin: 'dark', 
    routerTransition: 'zoom-fade',
    type: 'vertical',
    contentWidth: 'full', 
    menu: {
      hidden: false,
      isCollapsed: false,
    },
    navbar: {
      type: 'floating',
      backgroundColor: '', 
    },
    footer: {
      type: 'static',
    },
    customizer: true,
    enableScrollToTop: true,
  },
}
