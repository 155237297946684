export default [
  // {
  //   path: '/error-404',
  //   name: 'error-404',
  //   component: () => import('@/views/error/Error404.vue'),
  //   meta: {
  //     layout: 'full',
  //     resource: 'Auth',
  //     action: 'read',
  //   },
  // },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/login/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/Fence/fence',
    name: 'Fence-fence',
    component: () => import('@/views/pages/fence/index.vue'),
  },
  {
    path: '/Fence/fence/:action',
    name: 'Fence-fence-edit',
    component: () => import('@/views/pages/fence/fence-edit.vue'),
    meta: {
      contentClass: 'full-content',
      contentRenderer: 'sidebar-left',
      breadcrumb: [
        {
          text: '电子围栏管理',
          to: { name: 'Fence-fence' }
        },
        {
          text: '新增/编辑电子围栏',
          active: true,
        }
      ]
    }
  },
  {
    path: '/Track/track',
    name: 'Track-track',
    component: () => import('@/views/pages/track/index.vue'),
    meta: {
      contentClass: 'full-content',
      contentRenderer: 'sidebar-left'
    },
  },
  {
    path: '/Resources/terminal',
    name: 'Resources-terminal',
    component: () => import('@/views/pages/device/index.vue'),
  },
  {
    path: '/Resources/terminal/:action',
    name: 'Resources-terminal-edit',
    component: () => import('@/views/pages/device/device-edit.vue'),
    meta: {
      breadcrumb: [
        {
          text: '设备管理',
          to: { name: 'Permission-account' }
        },
        {
          text: '新增/编辑设备',
          active: true,
        }
      ]
    }
  },
  {
    path: '/Resources/personnel',
    name: 'Resources-personnel',
    component: () => import('@/views/pages/binder/index.vue'),
  },
  {
    path: '/Resources/personnel/:action',
    name: 'edit-personnel',
    component: () => import('@/views/pages/binder/binder-edit.vue'),
    meta: {
      breadcrumb: [
        {
          text: '人员管理',
          to: { name: 'Permission-account' }
        },
        {
          text: '新增/编辑人员',
          active: true,
        }
      ]
    }
  },
  {
    path: '/visitor/visitor',
    name: 'visitor-visitor',
    component: () => import('@/views/pages/binder/index.vue'),
  },
  {
    path: '/visitor/visitor/:action',
    name: 'edit-visitor',
    component: () => import('@/views/pages/binder/binder-edit.vue'),
    meta: {
      breadcrumb: [
        {
          text: '访客管理',
          to: { name: 'Permission-account' }
        },
        {
          text: '新增/编辑访客',
          active: true,
        }
      ]
    }
  },
  {
    path: '/Resources/area',
    name: 'Resources-area',
    component: () => import('@/views/pages/area/index.vue'),
  },
  {
    path: '/Resources/area/:action',
    name: 'Permission-area-edit',
    component: () => import('@/views/pages/area/area-edit.vue'),
    meta: {
      breadcrumb: [
        {
          text: '区域管理',
          to: { name: 'Permission-account' }
        },
        {
          text: '新增/编辑区域',
          active: true,
        }
      ]
    }
  },
  {
    path: '/Permission/log',
    name: 'Permission-log',
    component: () => import('@/views/pages/permission/Log/index.vue'),
  },
  {
    path: '/Permission/account',
    name: 'Permission-account',
    component: () => import('@/views/pages/permission/Account/index.vue'),
  },
  {
    path: '/Permission/account/:action',
    name: 'Permission-account-edit',
    component: () => import('@/views/pages/permission/Account/account-edit.vue'),
    meta: {
      breadcrumb: [
        {
          text: '账户管理',
          to: { name: 'Permission-account' }
        },
        {
          text: '新增/编辑账户',
          active: true,
        }
      ]
    }
  },
  {
    path: '/Permission/role',
    name: 'Permission-role',
    component: () => import('@/views/pages/permission/Role/index.vue'),
  },
  {
    path: '/Permission/role/:action',
    name: 'Permission-role-edit',
    component: () => import('@/views/pages/permission/Role/role-edit.vue'),
    meta: {
      breadcrumb: [
        {
          text: '角色权限管理',
          to: { name: 'Permission-role' }
        },
        {
          text: '新增/编辑角色',
          active: true,
        }
      ]
    }
  },
  {
    path: '/Permission/resource',
    name: 'Permission-resource',
    component: () => import('@/views/pages/permission/Menu/index.vue'),
  },
  {
    path: '/Permission/resource/:action',
    name: 'Permission-resource-edit',
    component: () => import('@/views/pages/permission/Menu/menu-edit.vue'),
    meta: {
      breadcrumb: [
        {
          text: '菜单管理',
          to: { name: 'Permission-role' }
        },
        {
          text: '新增/编辑菜单',
          active: true,
        }
      ]
    }
  },
  {
    path: '/Alarm/alarm',
    name: 'Alarm-alarm',
    component: () => import('@/views/pages/alarm/index.vue'),
  },
]
