require('@/assets/scss/toolBarStyle.scss');
require("@/assets/scss/FMap.scss");
import store from "@/store"
import trasformer from "@/libs/utils/coordTransformer";
// import { computed } from '@vue/composition-api'
import { deviceTypeLibs } from "@/libs/utils"

const interphone = require("@/assets/map-markers/interphone.png");
const alarmMarker = require("@/assets/map-markers/01.gif");
// const deviceTypes = computed(() => store.state.device.deviceTypes)

// 坐标转换
const trasformerMarker = ({ x, y }) => {
  return trasformer('map', { x, y });
}

// 播放摄像头
const getStaticInfoWin = (info, content) => {
  console.log('获取摄像头的信息弹窗', info)
  const infoWin = new fengmap.FMDomMarker({
    content,
    domWidth: "420",
    domHeight: "370",
    height: 1,
    anchor: fengmap.FMMarkerAnchor.BOTTOM,
  });
  return infoWin;
}

// 地图marker点击类型分发
const markerEvents = (marker) => {
  const { type_id, sn } = marker;
  // console.log(deviceTypes.value, type_id)
  // const { name: type } = deviceTypes.value[type_id]
  // marker = {
  //   ...marker,
  //   type
  // };
  const content = deviceTypeLibs[type_id].content(sn);
  return getStaticInfoWin(marker, content)
}

// 获取文字类型marker
const getTextMarker = (text) => {
  const textMarker = new fengmap
}


// 获取线段marker
const getLineMarker = ({ points, level, color , width = 4 }) => {
  var segment = new fengmap.FMSegment();
  segment.level = level;
  segment.points = points;

  const lineMarker = new fengmap.FMLineMarker({
    segments: [segment],
    animate: false,
    smooth: false,
    color,
    width,
    type: fengmap.FMLineType.FULL
  })
  return lineMarker
}

// 获取图片类型marker
const getCompositeMarker = ({ img, x, y, z, name = "", size = [56, 56], anchor = 'BOTTOM', style = 'ttext-bimage' }) => {
  console.log(fengmap.FMMarkerAnchor[anchor])
  const imageMarker = new fengmap.FMCompositeMarker({
    layout: {
      style
    },
    x,
    y,
    height: z,
    image: {
      url: img,
      size,
    },
    opacity: 0.9,
    text: {
      padding: [4, 4, 4, 4],
      plateColor: 'rgba(0,0,0,.4)',
      content: {
        fillColor: '#fff',
        text: name,
        fontSize: 9,
        strokeWidth: 0,
        fontWeight: 400,
      }
    },
    anchor: { anchor: fengmap.FMMarkerAnchor[anchor] },
  })
  return imageMarker
}

// 获取信息弹窗
const getInfoWin = (device) => {
  const content =
    `<div class="infoWin-content">
      <div class="infoWin-content-header">
        ${device.sn}
        <i id="${device.sn}-close" class="feather icon-x infoWin-close"></i>
      </div>
      <div class="infoWin-content-body">
      <div>
          <span>
            <i class="feather icon-image text-primary"></i>头像
          </span>
          <img src="${device.img || require('@/assets/map-markers/interphone.png')}"></img>
        </div>
        <div>
          <span>
            <i class="feather icon-user text-primary"></i>姓名：
          </span>
          <span>${device.binder && device.binder.name || '--'}</span>
        </div>
        <div>
          <span><i class="feather icon-users text-primary"></i>类型：</span>
          <span>访客</span>
        </div>
        <div>
          <span><i class="feather icon-phone text-primary"></i>联系方式：</span>
          <span>${device.binder && device.binder.phone || '--'}</span>
        </div>
        <div>
          <span><i class="feather icon-credit-card text-primary"></i>身份证：</span>
          <span>${device.binder && device.binder.idcard || '--'}</span>
        </div>
        <div>
          <span><i class="feather icon-activity text-primary"></i>最后定位时间：</span>
          <span>${device.last_online_time || '--'}</span>
        </div>
      </div>
    </div>`;

  const infoWin = new fengmap.FMDomMarker({
    content,
    domWidth: "420",
    domHeight: "370",
    height: 1,
    anchor: fengmap.FMMarkerAnchor.BOTTOM,
  });
  return infoWin;
}

// 绑定domMarker点击事件
const handleDomMrkerInfo = (device, callback) => {
  const domMarker = document.getElementById(`domMarker-${device.sn}`);
  if (domMarker && typeof domMarker.onclick != "function") {
    domMarker.onclick = (event) => {
      const popup = getInfoWin(device);
      callback(popup);
    };
  }
}

// 获取报警Dom类型的marker
const getAlarmDomMaker = ({ text, id, x, y }) => {
  const content = `<div id="domMarker-${id}" class="domContainer defauleDomMarker">
  <div class="domPinText">${text}</div>
  <div class="alarmDot"><img width="30" height="30" src="${alarmMarker}" /></div>
  <div class="pulse"></div><div class="pulse-big"></div></div>`
  const domMarker = new fengmap.FMDomMarker({
    content,
    x,
    y,
    domWidth: "30",
    domHeight: "30",
    height: 0,
    anchor: fengmap.FMMarkerAnchor.BOTTOM,
  })
  return domMarker
}

// 获取默认Dom类型的marker
const getDefaultDomMaker = ({ text, id }) => {
  const content = `<div id="domMarker-${id}" class="domContainer defauleDomMarker">
    <div class="domPinText">${text}</div><div class="domPin">
    <div style="border-color: #1b73f1; background-image: url(${interphone})" class="domTop"></div></div></div>`;
  const domMarker = new fengmap.FMDomMarker({
    content,
    domWidth: "40",
    domHeight: "56",
    height: 0,
    anchor: fengmap.FMMarkerAnchor.BOTTOM,
  });
  return domMarker;
};

export {
  getInfoWin,
  markerEvents,
  getAlarmDomMaker,
  getDefaultDomMaker,
  trasformerMarker,
  getLineMarker,
  getTextMarker,
  getCompositeMarker,
  handleDomMrkerInfo,
}