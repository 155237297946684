import { getDevideWithBinder, getDeviceTypes } from "@/libs/api/terminal"
import { deviceTypeLibs } from "@/libs/utils"
const state = {
  deviceList: [], // 所有终端列表
  deviceTypes: [] //所有设备类型
}

const mutations = {
  SET_DEVICE_LIST(state, val) {
    state.deviceList = val;
  },
  ADD_DEVICE(state, device) {
    state.deviceList.push(device);
  },
  SET_DEVICE_STATUS(state, device) {
    if (state.deviceList.length == 0) return;
    const index = state.deviceList.findIndex(res => {

    })
  },
  SET_DEVICE_TYPE(state, val) {
    state.deviceTypes = val
  }
}

const actions = {
  queryDeviceTypes({ state, commit }) {
    return new Promise((resolve, reject) => {
      if (state.deviceTypes.length != 0) return resolve(state.deviceTypes);
      getDeviceTypes().then(res => {
        if (res.data && res.data.code == 200 && 'DeviceType[]' in res.data) {
          console.log('请求设备类型完成', res.data['DeviceType[]'])
          const types = res.data['DeviceType[]'].reduce((crr, next) => {
            crr.push({
              ...next,
              ...deviceTypeLibs[next.id]
            })
            return crr;
          }, [])
          commit('SET_DEVICE_TYPE', types);
          console.log(types)
          return resolve(types)
        }
      })
    })

  },
  queryAllDevice({ state, commit }) {
    getDevideWithBinder({ type_id: 5 }).then(res => {
      if (res.data && res.data.code == 200 && res.data['[]']) {
        const list = res.data['[]'].reduce((crr, next) => {
          const item = {
            ...next.DeviceStatus,
            ...next.Device,
            binder: next.Binder,
          }
          crr.push(item);
          return crr;
        }, [])
        console.log('终端列表', list)
        commit('SET_DEVICE_LIST', list)
      }
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
