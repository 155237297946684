function paramsFilter(listQuery) {
  let params = {};
  for (const k in listQuery) {
    if (listQuery[k] === 0 || listQuery[k]) {
      if (typeof listQuery[k] === "string") {
        const isDateRange = listQuery[k].split('至');
        if (isDateRange.length == 2) {
          let stime = `${isDateRange[0]} 00:00:00`;
          let etime = `${isDateRange[1]} 23:59:59`;
          params[`${k}%`] = `${stime},${etime}`;
        } else {
          params[`${k}$`] = `%${listQuery[k]}%`;
        }
      } else {
        params[k] = listQuery[k];
      }
    }
  }
  return params;
}

// 电池电量颜色电量对应
const batteryColor = (num) => {
  if (num >= 70) return 'success';
  if (num < 70 && num > 30) return 'warning';
  if (num < 30) return 'danger';
};

// 操作類型
const actions = {
  'add': '新增',
  'edit': '编辑',
  'previe': '预览'
};

// 设备类型
const deviceTypeLibs = {
  // 定位终端
  5: {
    icon: 'MapPinIcon',
    color: 'primary'
  },
  // 蓝牙信标
  4: {
    icon: 'BluetoothIcon',
    color: 'success',
    img: require("@/assets/map-markers/Bluetooth.png")
  },
  // 摄像头
  3: {
    icon: 'VideoIcon',
    color: 'warning',
    img: require("@/assets/map-markers/camera.png"),
    content: (sn) => `<div class="infoWin-content">
    <div class="infoWin-content-header">
      ${sn}
      <i id="${sn}-close" class="infoWin-close feather icon-x"></i>
    </div>
    <div id="video-${sn}" class="infoWin-content-body"></div></div> `
  },
  // UWB标签
  2: {
    icon: 'MapPinIcon',
    color: 'primary'
  },
  // UWB基站
  1: {
    icon: 'RssIcon',
    color: 'success',
    img: require("@/assets/map-markers/anchor.png")

  },
}

const getNowTime = () => {
  var date = new Date();
  //年 getFullYear()：四位数字返回年份
  var year = date.getFullYear(); //getFullYear()代替getYear()
  //月 getMonth()：0 ~ 11
  var month = date.getMonth() + 1;
  //日 getDate()：(1 ~ 31)
  var day = date.getDate();
  //时 getHours()：(0 ~ 23)
  var hour = date.getHours();
  //分 getMinutes()： (0 ~ 59)
  var minute = date.getMinutes();
  //秒 getSeconds()：(0 ~ 59)
  var second = date.getSeconds();
  var time =
    year +
    "-" +
    addZero(month) +
    "-" +
    addZero(day) +
    " " +
    addZero(hour) +
    ":" +
    addZero(minute) +
    ":" +
    addZero(second);
  return time;
}
//小于10的拼接上0字符串
const addZero = (s) => {
  return s < 10 ? "0" + s : s;
}

export {
  paramsFilter,
  actions,
  getNowTime,
  deviceTypeLibs,
  batteryColor
}